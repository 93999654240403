.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.rocket-emoji {
  width: 50px; /* Set appropriate size for the rocket image */
  height: 50px;
  animation: rocketLaunch 1.5s infinite ease-in-out;
}

.loading-text {
  margin-top: 10px;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.5rem;
  color: #26274B;
}

@keyframes rocketLaunch {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
